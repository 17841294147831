import { RecipesService } from '../../services/RecipesService'
import { RecipeCategoryNewVM } from './RecipeCategoryNewVM'
import { RootStore } from '../../../stores/RootStore'
import { computed, observable, action, makeObservable, runInAction, IReactionDisposer, reaction } from 'mobx'
import { Recipe } from '../../aggregate/Recipe'
import { StepRowVM } from './StepRowVM'
import { CategoryRowVM } from './CategoryRowVM'
import { RecipeItemRowVM } from './RecipeItemRowVM'
import { arrayMove } from 'react-sortable-hoc'
import { IRecipeJobResult } from '../../interfaces/IRecipeItemJobResult'
import { StorageFilesPhotoService } from '../../../storage-files/services/StorageFilesPhotoService'
import { arrayMoveImmutable } from 'array-move'
import { StorageFile } from '../../../storage-files/aggregate/StorageFile'
import { StorageFilesReadService } from '../../../storage-files/services/StorageFilesReadService'
import { StorageFilesDownloadService } from '../../../storage-files/services/StorageFilesDownloadService'
import { StorageFilesService } from '../../../storage-files/services/StorageFilesService'
import { IStorageFileDTO } from '../../../storage-files/dtos/IStorageFileDTO'
import { deserialize } from 'serializr'
import { ParseIngredientResponse } from '../../interfaces/ParseIngredientResponse'

export class RecipeEditVM {
  private rootStore: RootStore
  public isNewRecipe: boolean = false
  private contentRef: HTMLIonContentElement
  private reactionDisposers: IReactionDisposer[] = []

  constructor(rootStore: RootStore, recipe: Recipe, isNew: boolean = false) {
    makeObservable(this)
    this.rootStore = rootStore
    this.recipe = recipe
    this.isNewRecipe = isNew
    this.categoryNewVM = new RecipeCategoryNewVM(this.rootStore, this)
    this.svc = new RecipesService(rootStore)
    this.rootStore.appStore.listenForSignalRUpdate('recipejobresult', (e) => this.processJobResult(e), true)
    this.loadRecipeItems()
    this.recipeItems.forEach((e) => e.dispatchParseRecipeItem())
    if (window.location.search.includes('fromimport')) this.toggleViewLinkedItems()
    this.loadStorageFiles()
    this.loadReactions()
  }

  @observable public addCategoryEvent: any = null
  @observable public categoryMenuShown: boolean = false
  @observable public categoryNewVM: RecipeCategoryNewVM = null
  @observable public recipe: Recipe = null
  @observable public deleteConfirmShown: boolean = false
  @observable public currentTabIndex: string = '0'
  @observable public editingRecipeItemGuid: string = null
  @observable public bulkEditMode: boolean = false
  public type: string = 'Recipe'
  @observable public autoSave: boolean = false
  @observable public viewLinkedItems: boolean = false
  public svc: RecipesService
  @observable public recipeItems: RecipeItemRowVM[] = []
  @observable public storageFiles: StorageFile[] = []
  @observable public imagesViewerShown: boolean = false
  @observable public imagesViewerIndex: number = 0
  @observable public generateImageModalShown: boolean = false
  @observable public mealsListModalShown: boolean = false

  private loadReactions() {
    this.reactionDisposers.push(
      reaction(
        () => this.rootStore.recipesStore.get(this.recipeGuid),
        (e) => this.checkForNewAttachments(e.clone())
      )
    )
  }

  public dispose() {
    this.reactionDisposers.forEach((e) => e())
  }

  @action
  private checkForNewAttachments(updatedRecipe: Recipe) {
    if (!updatedRecipe) return
    if (updatedRecipe.Attachments.length === this.recipe.Attachments.length) return
    this.recipe.Attachments = updatedRecipe.Attachments
    this.loadStorageFiles()
  }

  @computed
  public get recipeGuid(): string {
    return this.recipe.RecipeGuid
  }

  @action
  private processJobResult(result: string) {
    // if (result.JobType === 'Parse') {
    const response = new ParseIngredientResponse(JSON.parse(result))
    const item = this.recipeItems.find((e) => e.recipeItemGuid == response.RecipeItemGuid)
    if (!item) {
      console.log('No item found for', response.RecipeItemGuid)
      return
    }
    item.updateParseResult(response)
    // }
    // if (result.JobType === 'SuggestCategory') {
    //   const item = this.recipeItems.find((e) => e.recipeItemGuid === result.RecipeItemGuid)
    //   if (!item) return
    //   item.updateSuggestedCategoryResult(result)
    // }
  }

  @computed
  public get isEditingItem() {
    return Boolean(this.recipeItems.find((e) => e.isEditingItem))
  }

  @action
  public hideItemEditModal() {
    this.recipeItems.find((e) => e.isEditingItem).hideItemEditModal()
  }

  @action
  public setEditingRecipeItem(recipeItemGuid: string) {
    this.editingRecipeItemGuid = recipeItemGuid
  }

  @action
  public clearEditingRecipeItem() {
    this.editingRecipeItemGuid = null
  }

  @action
  public enableAutoSave() {
    this.autoSave = true
  }

  @action
  public toggleViewLinkedItems() {
    this.viewLinkedItems = !this.viewLinkedItems
  }

  @computed
  public get editingRecipeItem(): RecipeItemRowVM {
    return this.recipeItems.find((e) => e.recipeItemGuid === this.editingRecipeItemGuid)
  }

  public setContentRef(e: HTMLIonContentElement) {
    this.contentRef = e
  }

  @computed
  public get steps(): Array<StepRowVM> {
    return this.recipe.Steps.filter((e) => !e.IsDeleted)
      .sort((a, b) => (a.StepNumber < b.StepNumber ? -1 : 0))
      .map((e) => new StepRowVM(this.rootStore, this, e))
  }

  @computed
  public get categories(): Array<CategoryRowVM> {
    return this.rootStore.recipeCategoriesStore.currentBoardRecords
      .sort((a, b) => (a.Name < b.Name ? -1 : 0))
      .map((e) => new CategoryRowVM(this.rootStore, this, e))
  }

  @computed
  public get sortListHeight(): number {
    return this.rootStore.appStore.listHeight - 128
  }

  @computed
  public get selectedCategoriesCount(): number {
    return this.categories.filter((e) => e.isChecked).length
  }

  @action
  private loadRecipeItems() {
    this.recipeItems = this.recipe.RecipeItems.filter((e) => !e.IsDeleted)
      .sort((a, b) => (a.Rank < b.Rank ? -1 : 0))
      .map((e) => new RecipeItemRowVM(this.rootStore, this, e))
  }

  @computed
  public get name(): string {
    if (!this.recipe) return ''
    return this.recipe.Name
  }

  @action
  public setName(val: string) {
    this.recipe.setName(val)
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @computed
  public get linkUrl(): string {
    if (!this.recipe) return ''
    return this.recipe.LinkUrl
  }

  @action
  public setLinkUrl(val: string) {
    this.recipe.setLinkUrl(val)
  }

  @computed
  public get notes(): string {
    if (!this.recipe) return ''
    return this.recipe.Notes
  }

  @action
  public setNotes(val: string) {
    this.recipe.setNotes(val)
  }

  @action
  public toggleCategoryMenu(e) {
    this.addCategoryEvent = e
    this.categoryMenuShown = true
  }

  @action
  public addRecipeItem(description = '', rank: number = 1000) {
    const newItem = this.recipe.addRecipeItem(null, rank)
    newItem.setDescription(description)
    this.recipeItems.push(new RecipeItemRowVM(this.rootStore, this, newItem))
    const newItemRow = this.recipeItems.find((e) => e.recipeItemGuid === newItem.RecipeItemGuid)
    newItemRow.dispatchParseRecipeItem()
    newItemRow.setFocus()
  }

  @computed
  public get lastRecipeItem(): RecipeItemRowVM {
    return this.recipeItems[this.recipeItems.length - 1]
  }

  @action
  public back() {
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get recipesStore() {
    return this.rootStore.recipesStore
  }

  public getItemQuantity(itemGuid: string) {
    return 0
  }

  public isGotten(): boolean {
    return false
  }

  public toggleGotten() {}
  public markAsCleared() {}

  @action
  public showCategoryNewModal() {
    this.categoryNewVM.toggleShown()
  }

  @action
  public addStep(description = '', rank: number = 1000) {
    const newStep = this.recipe.addStep(rank)
    newStep.setDescription(description)
    const newStepRow = this.steps.find((e) => e.stepGuid === newStep.StepGuid)
    newStepRow.setFocus()
  }

  @action
  public save(silent: boolean = false) {
    this.svc.save(this.recipe.toDTO())
    if (this.autoSave) return
    if (silent) return
    this.rootStore.mealsStore.attachRecipeToDraftMeal(this.recipe.RecipeGuid)
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get selectedRecipeItems(): Array<RecipeItemRowVM> {
    const list = this.recipeItems.filter((e) => !e.isDeleted)
    return list
  }

  @action
  public deleteCategory(categoryGuid: string) {
    this.recipe.deleteCategory(categoryGuid)
  }

  @action
  public deleteRecipeItem(recipeItemGuid: string) {
    this.recipe.deleteRecipeItem(recipeItemGuid)
    if (this.recipeItems.length === 0) this.addRecipeItem()
    this.recipeItems.splice(
      this.recipeItems.findIndex((e) => e.recipeItemGuid === recipeItemGuid),
      1
    )
  }

  @action
  public deleteStep(stepGuid: string) {
    this.recipe.deleteStep(stepGuid)
    if (this.steps.length === 0) this.addStep()
  }

  @computed
  public get pageTitle(): string {
    return this.isNewRecipe ? 'Add Recipe' : this.recipe.Name
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @action
  public setCurrentTab(val: string) {
    if (this.currentTabIndex === val) return
    this.currentTabIndex = val
    if (!this.contentRef) return
    this.contentRef.scrollToTop()
  }

  public doneAddingItems() {}

  @action
  public delete() {
    this.svc.delete(this.recipe.toDTO())
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get selectedRecipeItemsOrdered() {
    return this.selectedRecipeItems.sort((a, b) => (a.rank < b.rank ? -1 : 0))
  }

  @action
  public reorderRecipeItems(oldIndex: number, newIndex: number) {
    const oldRows = this.selectedRecipeItemsOrdered.map((e) => e)
    const newRows = arrayMove(oldRows, oldIndex, newIndex)
    newRows.forEach((e, idx) => e.setRank(idx + 1))
  }

  @action
  public reorderSteps(oldIndex: number, newIndex: number) {
    const oldRows = this.steps.map((e) => e)
    const newRows = arrayMove(oldRows, oldIndex, newIndex)
    newRows.forEach((e, idx) => e.setRank(idx + 1))
  }

  @action
  public async disableAutoComplete(e: any) {
    if (!e) return
    const txt = await e.getInputElement()
    txt.setAttribute('autocomplete', 'something-new')
  }

  @action
  public async openLink() {
    window.open(this.linkUrl)
  }

  @action
  public openImagesViewer(idx: number): void {
    this.imagesViewerIndex = idx
    this.doOpenImagesViewer()
  }

  @action
  private doOpenImagesViewer(): void {
    this.imagesViewerShown = true
  }

  @action
  public closeImagesViewer(): void {
    this.imagesViewerShown = false
  }

  @action
  public openGenerateImage(): void {
    this.generateImageModalShown = true
  }

  @action
  public closeGenerateImageModal(): void {
    this.generateImageModalShown = false
  }

  @action
  public openMealsListModal(): void {
    this.mealsListModalShown = true
  }

  @action
  public closeMealsListModal(): void {
    this.mealsListModalShown = false
  }

  @computed
  public get recipeDescription(): string {
    let desc = this.recipe.Name
    if (this.notes) desc += ': ' + this.notes
    this.categories.forEach((e) => (desc += ' ' + e.name))
    this.recipeItems.forEach((e) => (desc += ' ' + e.description))
    this.steps.forEach((e) => (desc += ' ' + e.description))
    return desc
  }

  public async takePhoto() {
    const svc = new StorageFilesPhotoService(this.rootStore)
    const dto = await svc.takePhoto(true)
    if (!dto) return
    this.recipe.addAttachment(dto.StorageFileGuid)
    this.loadStorageFiles()
    this.save(true)
  }

  @action
  public async addAttachment(dto: IStorageFileDTO) {
    this.generateImageModalShown = false
    const sf = deserialize(StorageFile, dto)
    sf.setAvailableOffline(true)
    const svc = new StorageFilesDownloadService(this.rootStore)
    await svc.download(sf)
    sf.markAsDownloaded()
    const sfSvc = new StorageFilesService(this.rootStore)
    sfSvc.save(sf)
    this.recipe.addAttachment(sf.StorageFileGuid)
    this.loadStorageFiles()
    this.save(true)
  }

  @action
  public sortAttachments(oldIdx: number, newIdx: number): void {
    const newRows = arrayMoveImmutable(
      this.recipe.Attachments.sort((a, b) => (a.Rank < b.Rank ? -1 : 0)).slice(),
      oldIdx,
      newIdx
    )
    newRows.forEach((e, idx) => {
      const att = this.recipe.getAttachment(e.AttachmentGuid)
      att.setRank(idx + 1)
    })
    this.loadStorageFiles()
  }

  @action
  private loadStorageFiles() {
    let atts = this.recipe.Attachments.slice()
      .sort((a, b) => (a.Rank < b.Rank ? -1 : 0))
      .map((e) => this.rootStore.storageFilesStore.get(e.StorageFileGuid))
      .filter((e) => e)
    this.storageFiles = atts
  }

  @computed
  public get hasImagesFeature(): boolean {
    return this.rootStore.featuresStore.features.RecipeImages
  }

  @computed
  public get hasAIImagesFeature(): boolean {
    return this.rootStore.featuresStore.features.AIRecipeImages
  }
}
